import styled, { css } from 'styled-components';
import SectionWrap from 'src/components/shared/wraps/Section/Section';

export const Wrapper = styled.div`
  max-width: ${({ mw }) => (mw ? '100%' : '113rem')};
  margin: 0 auto 5rem;
  margin-bottom: ${({ last }) => last && '0 !important'};
  width: 100%;

  ${({ pl }) =>
    pl &&
    css`
      max-width: 100%;
      padding-left: calc((100vw - 114rem) / 2);
      padding-right: 0;
      margin: 0 0 5rem;
    `}

  ${({ pr }) =>
    pr &&
    css`
      max-width: 100%;
      padding-left: 0;
      padding-right: calc((100vw - 114rem) / 2);
      margin: 0 0 5rem;
    `}
  
  @media screen and (min-width: 1800px) {
    max-width: ${({ mw }) => (mw ? '100%' : '130rem')};

    ${({ pl }) =>
      pl &&
      css`
        max-width: 100%;
        padding-left: calc((100vw - 131rem) / 2);
      `}

    ${({ pr }) =>
      pr &&
      css`
        max-width: 100%;
        padding-right: calc((100vw - 131rem) / 2);
      `}
  }

  @media screen and (max-width: 1199px) {
    display: ${({ nonGrid }) => !nonGrid && 'grid'};
    grid-template-columns: 50% 50%;
    max-width: 100%;
    padding: 0 60px;
  }

  @media screen and (max-width: 991px) {
    padding: 0 40px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;

export const MainSection = styled.section`
  margin-bottom: 10rem;
`;

export const Section = styled(SectionWrap)`
  margin-bottom: ${({ mb }) => (mb ? mb : '10rem')};
  display: flex;
  padding-left: 0;
  padding-right: 0;

  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
`;
