import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import theme from 'src/styles/theme';
import GlobalStyle from 'src/styles/GlobalStyle';
import Contact from 'src/components/Sites/Home/Contact/Contact';
import TranslationsProvider from '../translations/TranslationsProvider';
import {ScrollbarProvider} from "./Layout";

const ScrollbarWrapper = styled.div`
  height: 100vh;
  width: 100%;
`;

const StyledWrapper = styled.div`
  .scrollbar-track-y {
    z-index: 99999;
  }
`;

const LayoutCV = ({
  children,
  nonBgc,
  isLinkToTop,
  transitionStatus,
  entry,
  exit,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <TranslationsProvider>
        <StyledWrapper>
          <ScrollbarProvider isLinkToTop={isLinkToTop}>
            <GlobalStyle />
            {/*<TransitionWrapper*/}
            {/*  transitionStatus={transitionStatus}*/}
            {/*  entry={entry}*/}
            {/*  exit={exit}*/}
            {/*>*/}
              {children}
              <Contact nonBgc={nonBgc} work/>
            {/*</TransitionWrapper>*/}
          </ScrollbarProvider>
        </StyledWrapper>
      </TranslationsProvider>
    </ThemeProvider>
  );
};

LayoutCV.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  nonBgc: PropTypes.bool,
  showIntro: PropTypes.bool,
  isLinkToTop: PropTypes.bool,
};

LayoutCV.defaultProps = {
  className: '',
  nonBgc: false,
  showIntro: false,
  isLinkToTop: false,
};


export default LayoutCV